<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}菜单</div>

        <div class="content" v-loading="loading">
            <el-form class="column2" label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="父级菜单" prop="parentId" ref="parentId">
                    <Treeselect :normalizer="normalizer" @input="changeParentId" placeholder="请选择父级菜单" v-model="form.parentId" :options="treeselectOptions" />
                </el-form-item>

                <el-form-item :label="form.typeId==1?'按钮名称':'菜单名称'" prop="menuName">
                    <el-input v-model="form.menuName" :placeholder="`请输入${form.typeId==1?'按钮':'菜单'}名称`" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item v-if="form.typeId==0" label="菜单别名" prop="nickname">
                    <el-input v-model="form.nickname" placeholder="请输入菜单名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="权限标识" prop="code">
                    <el-input v-model="form.code" placeholder="请输入权限标识" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="类型" prop="typeId">
                    <el-radio-group v-model="form.typeId" size="medium">
                        <el-radio label="1">按钮</el-radio>
                        <el-radio label="0">菜单</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="菜单链接" prop="href" v-if="form.typeId==0">
                    <el-input v-model="form.href" placeholder="请输入菜单链接" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="linkTo" prop="href" v-if="form.typeId==0">
                    <el-input v-model="form.linkTo" placeholder="请输入linkTo" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="组件路径" prop="component" v-if="form.typeId==0">
                    <el-input v-model="form.component" placeholder="请输入组件路径" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="图标" prop="icon"  v-if="form.typeId==0">
                    <el-autocomplete
                        popperClass="autocompleteIcon"
                        @keyup.enter.native="submit"
                        v-model="form.icon"
                        :fetch-suggestions="regularQuerySearch"
                        placeholder="请输入图标"
                        @select="regularHandleSelect"
                    >
                        <div slot-scope="{item}" :class="`iconfont icon${item['font_class']}`"></div>
                    </el-autocomplete>
                </el-form-item>

                <el-form-item label="显示位置" prop="position" v-if="form.typeId==0">
                    <el-radio-group v-model="form.position" size="medium">
                        <el-radio label="top">头部</el-radio>
                        <el-radio label="left">左侧</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="排序" prop="sort" v-if="form.typeId==0">
                    <el-input v-model="form.sort" placeholder="请输入排序" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="是否启用" prop="status">
                    <el-radio-group v-model="form.status" size="medium">
                        <el-radio label="1">启用</el-radio>
                        <el-radio label="0">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import iconJson from '@/styles/icon/iconfont.json'
export default {
    components: {
        Treeselect: () => import('@riophae/vue-treeselect'),
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
        listData: {
            type: Array,
            default:function(){
                return []
            }
        },
        treeselectOptions: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            form: {},
            groupData:[],
            rules: {
                parentId: [
                    {required: true, message: '请选择父级菜单', trigger: ['blur','change']},
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(this.form.id==value){
                                callback(new Error('父级菜单不能为自身'));
                            };
                            callback();
                        }
                    }
                ],
                typeId: [{required: true, message: '请选择权限类型', trigger: ['blur','change']}],
                code: [
                    {required: true, message: '请填写权限标识', trigger: ['blur','change']},
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(this.listData.some(r=>{return r.id!=this.form.id&&r.code==value})){
                                callback(new Error('权限标识不能重复'));
                            };
                            callback();
                        }
                    }
                ],
            },
        };
    },
    inject:['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                // this.$emit('update:loading', false);
                this.showEditDialog = newVal;
            }
        },
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){

                this.form = newVal;
                this.loading = false;
            }
        }
    },
    created() {

    },
    methods: {
        regularQuerySearch(queryString, cb) {
            var restaurants = iconJson.glyphs;
            var results = queryString ? restaurants.filter(this.regularCreateFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        regularCreateFilter(queryString) {
            return (restaurant) => {
                return (`${iconJson['css_prefix_text']}${restaurant['font_class']}`.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        regularHandleSelect(item) {
            this.form.icon = `${iconJson['css_prefix_text']}${item['font_class']}`;
        },
        normalizer(node) {
            return {
                id: node.id,
                label: node.menuName,
                children: node.children,
            }
        },
        changeParentId(){
            this.$refs.form.validateField('parentId');
        },
        submit(){
            this.$refs.form.validate((valid,obj) => {
                if (valid) {

                    this.submitLoading = true;

                    var key = this.form.id?'edit':'add';

                    this.$api.menu[key](this.form).then( res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");


                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj)
                };
            });
        },

    },
};
</script>
